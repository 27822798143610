// 非旅游险的保险投保页面

// 登录页、注册页 LOGO映射关系
// 会员id: logo路径
export const logoMap = {
	default: require('@/assets/image/logo.png'), // 默认Logo
	niuMo: require('@/assets/image/sundry/niuMo.png'), // 牛魔有道
};

class Params {
	hostname = window.location.hostname;

	// local_       本地环境
	// test_        测试环境
	// dev_         生产环境
	// 无前缀是当前使用的数据

	// 产品详情页，价格后面添加“起”字
	local_priceAddFontGoodsIds = ['75583'];
	test_priceAddFontGoodsIds = ['75583'];
	dev_priceAddFontGoodsIds = ['18526'];
	priceAddFontGoodsIds = this.getParams('priceAddFontGoodsIds');

	// 产品详情页，不显示计划费率
	local_priceCheckList = ['75583'];
	test_priceCheckList = ['75583'];
	dev_priceCheckList = ['18526'];
	priceCheckList = this.getParams('priceCheckList');

	/**
	 * 获取变量名
	 * @param {String} keyStr 变量名无前缀字符串
	 */
	getParams(keyStr) {
		let key = '';
		if (this.hostname.includes('192.168.')) {
			// 本地环境
			key = `local_${keyStr}`;
		} else if (this.hostname.includes('testh5.')) {
			// 测试环境
			key = `test_${keyStr}`;
		} else {
			// 生产环境
			key = `dev_${keyStr}`;
		}
		return this[key];
	}

	/**
	 * 是否为生产环境
	 * @returns Boolean
	 */
	isDev() {
		let r = true;
		if (this.hostname.includes('192.168.') || this.hostname.includes('127.0.0.1')) {
			// 本地环境
			r = false;
		} else if (this.hostname.includes('testh5.')) {
			// 测试环境
			r = false;
		}
		return r;
	}
}

let p = new Params();

export const priceAddFontGoodsIds = p.priceAddFontGoodsIds;
export const priceCheckList = p.priceCheckList;
export const isDev = p.isDev();
