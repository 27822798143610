<style lang="scss" scoped>
.cell_title,
.cell_value {
	color: $font_color_thd;
	font-size: 0.24rem;
}
.product_detail {
	width: 100%;
	height: 100%;
}
.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}
.sec_title {
	font-size: 0.28rem;
	color: $font_color_main;
	font-weight: bold;
}
.banner {
	margin-top: 0.2rem;
	display: flex;
	justify-content: center;
	img {
		width: 6.9rem;
		height: 3.91rem;
	}
}
.price_info {
	padding: 0.4rem 0.3rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.price {
		color: $color_active;
		display: flex;
		align-items: baseline;
		.integer {
			font-size: 0.56rem;
			font-weight: bold;
			line-height: 0.42rem;
		}
		.icon {
			font-size: 0.3rem;
		}
		.float {
			font-size: 0.36rem;
		}
	}
	.sales {
		color: $font_color_info;
		font-size: 0.24rem;
	}
}

.product {
	padding: 0.08rem 0.3rem 0 0.3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	img {
		height: 0.25rem;
	}
}

.product_info {
	padding: 0.19rem 0.3rem;
	font-size: 0.24rem;
	color: $font_color_main;
	display: flex;
	align-items: center;
	.line {
		width: 1px;
		height: 0.25rem;
		background-color: $font_color_main;
		margin: 0 0.16rem;
	}
	.icon {
		margin-left: 0.16rem;
	}
}

.product_tags {
	display: flex;
	align-items: center;
	padding: 0.1rem 0.3rem;
	li {
		height: 0.32rem;
		font-size: 0.24rem;
		padding: 0.04rem 0.12rem;
		color: $color_active_2;
		background-color: rgba($color: $color_main, $alpha: 0.1);
		margin-left: 0.1rem;
	}
	.active {
		color: $color_active;
		background-color: rgba($color: $color_active, $alpha: 0.1);
		margin-left: 0;
	}
}

.plain {
	margin-top: 0.49rem;
	padding: 0 0.3rem;
}
.plain_cont {
	padding: 0.3rem 0;
}

.duty {
	padding: 0.14rem 0.3rem;
	margin-top: 0.16rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.btn {
		font-size: 0.24rem;
		color: $font_color_main;
		.btn_text {
			margin-right: 0.1rem;
		}
	}
}
.duty_list {
	// padding: 0 .3rem;
	li {
		padding: 0.24rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $font_color_main;
		font-size: 0.24rem;
		// line-height: .24rem;
		.money_box {
			display: flex;
			align-items: center;
		}
		.money {
			margin-right: 0.1rem;
		}
	}
	.see_more {
		margin: 0.12rem 0 0.06rem 0;
		justify-content: center;
		.text {
			margin-right: 0.1rem;
		}
	}
	.line {
		padding: 0;
		height: 1px;
		background-color: #ddd;
	}
}

.count {
	margin: 0.39rem 0.3rem 0.31rem;
	height: 0.8rem;
	border-radius: 8px;
	font-size: 0.3rem;
	text-align: center;
	color: #fff;
	background-color: $color_main;
	line-height: 0.8rem;
}

.more_detail {
	padding: 0 0.3rem;
	font-size: 0.24rem;
	color: $font_color_info;
	margin-bottom: 0.57rem;
	margin-top: 1.07rem;
	.link {
		color: $color_main;
	}
}

.tab {
	padding: 0.2rem 0.3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0;
	background-color: #fff;
	li {
		text-align: center;
	}
	.text {
		font-size: 0.3rem;
		font-weight: bold;
	}
	span {
		display: inline-block;
		width: 0.3rem;
		height: 0.06rem;
		background-color: #fff;
		margin-top: 0.14rem;
	}
	.active .text {
		color: $color_main;
	}
	.active span {
		background-color: $color_main;
	}
}
.tab_float {
	position: fixed;
	top: 0.88rem;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	box-shadow: 0 0 4px #ddd;
}

.buy_btn_box {
	.cont {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1.1rem;
		display: flex;
		align-items: center;
		border-top: 1px solid #eeeeee;
		font-size: 0;
		.price {
			background-color: #fff;
			width: 3rem;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.text {
				font-size: 0.24rem;
				color: $font_color_main;
				line-height: 0.24rem;
				margin-right: 0.08rem;
			}
			.icon {
				font-size: 0.24rem;
				color: #ff5702;
			}
			.num {
				font-size: 0.36rem;
				color: #ff5702;
				line-height: 0.4rem;
			}
			.price_content {
				display: flex;
				align-items: baseline;
			}
		}
		.btn {
			height: 100%;
			flex: 1;
			font-size: 0.28rem;
			line-height: 1.1rem;
			text-align: center;
			color: #fff;
			background-image: url(../../assets/icon/buy.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}
	.back_dom {
		height: 100%;
		height: 1.1rem;
	}
}

::v-deep .age_box {
	position: relative;
	right: 6px;
	.van-dropdown-menu__bar {
		box-shadow: none;
	}
	.van-ellipsis {
		font-size: 0.28rem;
	}
}
.age_cell_title {
	display: flex;
	align-items: center;
	font-size: 0.28rem;
	color: #333;
}
.calculateForm_value {
	color: #333;
}
.calculateForm_content {
	padding-bottom: 1.1rem;
}
.calculateForm_product_name {
	font-size: 0.32rem;
	font-weight: bold;
}

.enter_process_pop {
	z-index: 3001 !important;
	.content {
		font-size: 0.24rem;
		color: $font_color_main;
		padding: 0.2rem 0.3rem;
	}
	.link {
		color: $color_main;
	}
	.section {
		padding: 0.2rem 0;
		text-indent: 2em;
		line-height: 0.4rem;
	}
	.footer {
		text-indent: 2em;
	}
}
.about {
	color: $font_color_main;
	font-size: 0;
	text-align: center;
	padding: 0.3rem 0.3rem 0.8rem;
	.header {
		font-size: 0.3rem;
		line-height: 0.48rem;
		font-weight: bold;
	}
	.title {
		font-size: 0.3rem;
		line-height: 0.4rem;
		margin-top: 0.2rem;
		margin-bottom: 0.1rem;
	}
	.section {
		font-size: 0.24rem;
	}
	ul {
		margin-top: 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
		font-size: 0.2rem;
		li {
			display: flex;
			align-items: center;
			.icon {
				font-size: 0.6rem;
				color: $font_color_info;
			}
			.text {
				font-size: 0.16rem;
				margin-left: 0.1rem;
				p {
					line-height: 0.26rem;
				}
			}
		}
	}
}

::v-deep .calculateForm_content {
	.van-dropdown-item__content {
		max-height: 30vh;
	}
}

.line_box {
	padding: 0.5rem 0;
	.line {
		height: 1px;
		background-color: #ddd;
	}
}

.weChat_warning {
	font-size: 0.4rem;
	text-align: center;
}

::v-deep .ly-tab-item {
	align-items: flex-start !important;
}

::v-deep .duty_list {
	.van-cell__title {
		flex: 2;
	}
}
::v-deep .plan_price {
	.van-cell__title {
		flex: 1;
	}
	.van-cell__value {
		flex-shrink: 0;
		.van-field__control {
			text-align: right;
		}
	}
}
.add_font {
	margin-left: 4px;
}

.special_text {
	padding: 0 0.3rem;
	font-size: 0.2rem;
	color: $danger;
}
</style>

<template>
	<div class="product_detail" ref="body" @scroll.passive="touchmove">
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信或支付宝中打开</h4>
		</div>
		<div v-else>
			<header-nav ref="nav" :hasLogout="!Boolean(userDesc)">产品详情</header-nav>
			<div class="banner">
				<img v-if="productInfo.proPicUrlIndexBig" :src="productInfo.proPicUrlIndexBig" alt="" />
			</div>
			<div class="price_info">
				<span class="price">
					<span class="icon">￥</span>
					<span class="integer" v-text="priceInt">5</span>
					<span class="float" v-text="`.${priceFloat}`">.00</span>

					<!-- 特殊处理：装潢人员意外保障，价格后面添加“起”字 -->
					<span class="float add_font" v-if="priceAddFontGoodsIds.includes(String(productInfo.id))"> 起</span>
				</span>
				<span class="sales" v-text="`销量：${productInfo.popularity}`"></span>
			</div>

			<!-- 保险名称 -->
			<div class="product">
				<span class="name main_title" v-text="productInfo.name">“耀之冠军”综合赛事保障</span>
				<img :src="insurerInfo.iconUrlSmallbrand" alt="" />
			</div>

			<div class="product_info">
				<span>保障年龄：</span>
				<span v-text="productInfo.insureAge"></span>
				<span class="line"></span>
				<span>保障期限：</span>
				<span v-text="viewTimeFormatter(planPriceChecked.viewTime)"></span>
				<span v-if="false" class="icon arrow_top"></span>
			</div>

			<!-- 广告语 -->
			<ul v-if="false" class="product_tags">
				<li v-for="(text, index) in ['适合30天内出行人士', '急性病保障', '多重保险性价比高']" :key="index" v-text="text" :class="{ active: !index }"></li>
			</ul>

			<!-- 保障计划 -->
			<div class="plain main_title">保障计划</div>
			<div class="plain_cont">
				<ly-tab v-model="selectedIndex" @change="protectPlainChange" :items="protectPlainList" :options="options"> </ly-tab>
			</div>

			<!-- 计划费率 -->
			<van-field v-if="!priceCheckList.includes(productId)" :label="planPriceLabel" :value="planPriceValue" class="plan_price" label-class="cell_title" value-class="cell_value" :border="true" is-link readonly clickable name="picker" placeholder="请选择工程造价" @click="planPricePop = true" />
			<van-popup v-model="planPricePop" position="bottom" :style="{ height: '30vh' }">
				<van-picker :title="planPriceLabel" show-toolbar value-key="eleUse1" :visible-item-count="3" :columns="planPriceList" @confirm="planPriceCheck" @cancel="planPricePop = false" />
			</van-popup>

			<div class="special_text" v-if="specialText" v-text="specialText"></div>

			<!-- 保障责任 -->
			<div class="duty">
				<span class="main_title">保障责任</span>
				<div class="btn" v-if="false">
					<span class="btn_text">展开</span>
					<span class="arrow_down_info"></span>
				</div>
			</div>
			<ul class="duty_list">
				<van-cell-group :border="false">
					<template v-for="(item, index) in dutyList">
						<van-cell :key="index" :title="item.contentName" :value="item.price" @click="showDutyDetail(item)" title-class="cell_title" value-class="cell_value" :border="false" is-link />
					</template>
				</van-cell-group>
				<template v-if="protectPlainList.length">
					<li class="see_more" v-show="!dutyShowAll && protectPlainList[selectedIndex].contents.length !== dutyList.length">
						<span class="text" @click="dutyShowAll = true">查看更多</span>
						<span class="arrow_right"></span>
					</li>
				</template>
				<li class="line"></li>
			</ul>

			<!-- 保费试算 -->
			<div class="count" v-if="false" @click="calculate">保费试算</div>
			<div class="more_detail">
				更多详情，请阅读
				<span class="link" @click="showInsuranceInformation">《投保须知》</span>
				和
				<span class="link" @click="openProvisionPage">《投保条款》</span>
			</div>

			<!-- 关于 -->
			<div class="about" v-if="false">
				<p class="header">-关于耀保网-</p>
				<p class="title">耀保网北京赛福哈博保险经纪旗下产品</p>
				<p class="section">是一家专注为企业定制行业风险管理解决方案的互联网保险服务平台。</p>
				<ul>
					<li>
						<span class="icon iconfont ybx_xingxing"></span>
						<div class="text">
							<p>定制化</p>
							<p>保险产品</p>
						</div>
					</li>
					<li>
						<span class="icon iconfont ybx_24xiaoshiqiantai"></span>
						<div class="text">
							<p>24小时</p>
							<p>服务热线</p>
						</div>
					</li>
					<li>
						<span class="icon iconfont ybx_kefu"></span>
						<div class="text">
							<p>专属顾问</p>
							<p>温馨服务</p>
						</div>
					</li>
				</ul>
			</div>

			<div class="line_box">
				<div class="line"></div>
			</div>
			<!-- 产品解读 -->
			<insureProductNarrate v-show="informationAreaShow" :information="information" :questionList="questionListShow" />

			<page-footer></page-footer>
			<price-buy-btn :price="priceString" :format="false" @buy="buy"></price-buy-btn>

			<!-- 信息弹出层 -->
			<bottom-info-pop v-model="infoPop" :title="infpPopData.title" :content="infpPopData.text"></bottom-info-pop>

			<!-- 保费试算 -->
			<van-action-sheet v-model="calculatePop" title="保费试算">
				<div class="calculateForm_content">
					<van-cell-group :border="false">
						<van-cell :title="productInfo.name" title-class="calculateForm_product_name"></van-cell>
						<van-cell title="购买份数" :value="`${calculateForm.num}份`" value-class="calculateForm_value"></van-cell>
						<van-cell title="承保年龄" title-class="age_cell_title" :border="true">
							<van-dropdown-menu slot="extra" class="age_box" direction="up">
								<van-dropdown-item v-model="calculateForm.age" :disabled="ageLimit.length === 1" :options="ageLimit" />
							</van-dropdown-menu>
						</van-cell>
						<van-cell title="保障期限" title-class="age_cell_title" :border="true">
							<van-dropdown-menu slot="extra" class="age_box" direction="up">
								<van-dropdown-item v-model="calculateForm.totalDays" @change="getPrice" :options="insureTimeList" />
							</van-dropdown-menu>
						</van-cell>
					</van-cell-group>
				</div>
			</van-action-sheet>

			<!-- 购买确认弹出层 -->
			<van-action-sheet v-model="warningPop" class="enter_process_pop" title="确认进入投保流程" @cancel="stopKhs" @click-overlay="stopKhs">
				<div class="content">
					<p class="header">尊敬的客户：</p>
					<p class="section">您即将进入投保流程，请仔细阅读保险条款。本保险产品由《<span class="link" v-text="insurerFullName"></span>》承保，由耀保网协助理赔。</p>
					<p class="footer">投保前请你仔细阅读<a class="link" :href="docToConsumerLink" target="_blank">客户告知书</a></p>
				</div>
				<div class="count" @click="enterProcess">确认开始投保</div>
			</van-action-sheet>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, ActionSheet, DropdownMenu, DropdownItem, Toast, Field, Popup, Picker } from 'vant';
import { loginNoPwd, productDetail, productDictInfo, getPrice, getInsuerInfo } from '@/request/api';
import { http_getDict, http_insDatingBack } from '@/request/common';
import { http_getPalns } from '@/request/sundry';
import headerNav from './components/headerNav.vue';
import { priceAddFontGoodsIds, priceCheckList } from '@/config/sundryParams';
import insureProductNarrate from '@/components/insureProductNarrate';

export default {
	name: 'sundryProduct',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[ActionSheet.name]: ActionSheet,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem,
		[Field.name]: Field,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		headerNav,
		insureProductNarrate,
	},
	data() {
		return {
			userDesc: '',
			weChatWarningShow: false,
			scrollY: 0,
			// 产品信息
			productInfo: {},
			banner: '',

			// 保险公司信息
			insurerInfo: {},

			// 保障计划
			protectPlainList: [],
			selectedIndex: 0,
			options: {
				activeColor: '#1d98bd',
				labelKey: 'comboName',
				// 可在这里指定labelKey为你数据里文字对应的字段名
			},
			dutyShowAll: false,

			// 底部信息弹窗层
			infoPop: false,
			infpPopData: {
				title: '',
				text: '',
			},

			// tab是否要浮动在顶部
			tabFloat: false,
			tabOffsetTop: 0,
			tabActive: 0,

			// 其它信息
			information: {},
			informationAreaShow: false,

			// 保费试算弹窗
			calculatePop: false,
			// 承保年龄
			calculateForm: {
				num: 1,
				age: NaN,
				totalDays: NaN,
			},
			// 承保年龄
			ageLimit: [],
			// 保障期限
			insureTimeList: [],

			price: '0.00',

			// 理赔指南
			guideList: [],

			// 常见问题
			questionListShowAll: false,
			questionList: [],

			warningPop: false,
			docToConsumerLink: '',
			insurerFullName: '',

			product: {},

			plainId: '', // 选择的计划的id

			companyId: '', // 保险公司id

			pageMap: {}, // 页面与商品映射关系

			// 计划费率
			planPriceLabel: '',
			planPricePop: false,
			planPriceList: [],
			planPriceValue: '',
			planPriceChecked: {},
			planPriceId: '',

			productId: '',
			parentId: '',
			planName: '', // 计划名称
			specialTextList: [],

			// 特殊处理
			priceAddFontGoodsIds: [], // 价格后面添加“起”字的商品id
			priceCheckList: [], // 不显示计划费率的商品id

			userId: '',
		};
	},
	computed: {
		priceInt() {
			return this.price.split('.')[0];
		},
		priceFloat() {
			return this.price.split('.')[1];
		},
		priceString() {
			if (this.priceAddFontGoodsIds.includes(String(this.productInfo.id))) {
				return `${this.price} 起`;
			} else {
				return this.price;
			}
		},
		// 保障责任
		dutyList() {
			let result = [];
			if (this.protectPlainList.length) {
				if (!this.dutyShowAll) {
					result = this.protectPlainList[this.selectedIndex].contents.slice(0, 3);
				} else {
					result = this.protectPlainList[this.selectedIndex].contents;
				}
			}
			return result;
		},

		questionListShow() {
			let result = [];
			if (this.questionList.length) {
				if (!this.questionListShowAll) {
					result = this.questionList.slice(0, 3);
				} else {
					result = this.questionList;
				}
			}
			return result;
		},

		specialText() {
			let r = '';
			let list = this.specialTextList.filter(item => item.code === `nmpdst-${this.parentId}` && item.value === this.productId);
			if (list.length) {
				r = list[0].remark;
			}
			return r;
		},
	},
	created() {
		this.$store.commit('clear_keepAlivePage');
		this.priceAddFontGoodsIds = priceAddFontGoodsIds;
		this.priceCheckList = priceCheckList;
		this.getMap();
		this.getSpecialText();
		this.productId = this.$route.query.productId;
		this.parentId = this.$route.query.pid;
		this.userDesc = this.$route.query.user;
		this.getProductDetail(this.$route.query.productId);
		this.getPlain(this.$route.query.productId);
		this.companyId = this.$route.query.companyId;
		this.userId = localStorage.getItem('khsSundryUserId') ? localStorage.getItem('khsSundryUserId') : new Date().getTime();
	},

	beforeRouteLeave(to, from, next) {
		this.scrollY = document.querySelector('.product_detail').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$nextTick(() => {
				vm.$refs.body.scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		stopKhs() {
			khs.stop();
		},
		// 保障期限格式化
		viewTimeFormatter(text) {
			if (text) {
				return text.split('（')[0];
			} else {
				return '';
			}
		},

		back() {
			// this.$router.push('goods')
			this.$router.go(-1);
		},

		// 去登录页
		toLoginPage(err) {
			if (err.message === '用户未登录') {
				this.$router.push({
					path: 'login',
					query: {
						pid: this.$route.query.pid,
						user: this.userDesc,
					},
				});
			}
		},

		// 去订单查询页面
		toOrderSearchPage() {
			this.$router.push('orderSearch');
		},

		login() {
			return new Promise(resolve => {
				loginNoPwd(this.$store.state.user).then(res => {
					resolve();
				});
			});
		},

		// 获取产品信息
		getProductDetail(productId) {
			productDetail(productId)
				.then(res => {
					this.productInfo = res.product;
					this.insurerInfo = res.insurer;
					// this.protectPlainList   = res.combos;
					this.information = res.information;
					this.questionList = res.questionList;

					this.informationAreaShow = true;
					this.tabActive = this.information.productCase ? 0 : 1;
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},

		// 获取计划
		getPlain(productId) {
			http_getPalns(productId)
				.then(res => {
					this.protectPlainList = res;
					if (res.length) {
						this.protectPlainChange(res[0]);
					}
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},

		// 切换保障计划
		protectPlainChange(planDict) {
			this.plainId = planDict.id;
			this.planName = planDict.comboName;
			this.$store.commit('set_plainId', planDict.id);

			this.planPriceList = planDict.productPriceList;
			this.$store.commit('set_priceList', planDict.productPriceList);
			if (this.planPriceList.length) {
				this.planPriceCheck(this.planPriceList[0]);
			}
		},

		// 选择计划费率
		planPriceCheck(priceDict) {
			this.planPriceChecked = priceDict;
			this.price = this.$base.floatPrice(priceDict.price);
			this.planPriceLabel = priceDict.eleView1;
			this.planPriceValue = priceDict.eleUse1;
			this.planPriceId = priceDict.priceId;
			this.planPricePop = false;
		},

		// 显示底部弹出层
		showInfoPop(title, contentText) {
			this.infpPopData = {
				title: title,
				text: contentText,
			};
			this.infoPop = true;
		},

		// 查看保障责任详情
		showDutyDetail(detail) {
			this.showInfoPop(detail.contentName, detail.dutyInstruction);
		},

		// 投保须知
		showInsuranceInformation() {
			this.showInfoPop('投保须知', this.information.hint);
		},

		// 打开投保条款页面
		openProvisionPage() {
			this.$router.push({
				name: 'safeguardClause',
				params: {
					productId: this.productId,
					comboId: this.plainId,
				},
			});
		},

		// 年龄段去重
		unique(arr) {
			let result = [];
			let compareValList = [];
			arr.forEach(item => {
				if (!compareValList.includes(item.text)) {
					result.push(item);
					compareValList.push(item.text);
				}
			});
			return result;
		},

		// 保费试算
		calculate() {
			productDictInfo(this.$store.state.productId, this.protectPlainList[this.selectedIndex].comboId)
				.then(res => {
					this.ageLimit = res.ageBelongList[0].ageLimit.map((item, index) => {
						return {
							text: `${item.min}-${item.max}周岁`,
							value: index,
							data: item,
						};
					});
					this.ageLimit = this.unique(this.ageLimit);

					this.insureTimeList = res.insureTimeList.map(item => {
						item.text = item.view_time;
						item.value = item.totalDays;
						return item;
					});

					if (this.ageLimit.length) {
						this.calculateForm.age = this.ageLimit[0].value;
					}

					if (this.insureTimeList.length) {
						this.calculateForm.totalDays = this.insureTimeList[0].value;
						this.getPrice(this.calculateForm.totalDays);
					}
					this.calculatePop = true;
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},

		// 获取价格
		getPrice(val) {
			let timeDict = this.insureTimeList.filter(item => item.value === val)[0];
			let send = {
				comboId: this.protectPlainList[this.selectedIndex].comboId,
				eleView1: '',
				eleView2: '',
				insureTime: timeDict.insure_time,
				insureTimeUnit: timeDict.insure_time_unit,
				priceId: timeDict.price_id,
				proId: this.productInfo.id,
				sex: '',
				viewAgeBelong: '',
				viewTime: timeDict.view_time,
			};
			getPrice(send)
				.then(res => {
					this.price = this.$base.floatPrice(res.priceInfo.price);
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},

		// 获取商品与页面映射关系
		getMap() {
			http_getDict('niuMoProductMap')
				.then(res => {
					this.pageMap = {};
					res.forEach(item => {
						this.pageMap[item.code] = item.value.split(',');
					});
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},

		// 获取特殊显示字符字典
		getSpecialText() {
			http_getDict('niuMoProductDetailSpecialText')
				.then(res => {
					this.specialTextList = res;
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},

		// 进入流程
		enterProcess() {
			this.warningPop = false;
			this.$nextTick(() => {
				let routeName = '';
				for (const key in this.pageMap) {
					if (Object.hasOwnProperty.call(this.pageMap, key)) {
						const ids = this.pageMap[key];
						if (ids.includes(String(this.productInfo.id))) {
							routeName = key;
							break;
						}
					}
				}
				if (!routeName) {
					return Toast('请检查数据字典配置');
				}
				http_insDatingBack({ content: '确认进入投保流程', proId: this.productInfo.id });

				this.$store.commit('add_keepAlivePage', routeName);
				this.$router.push({
					path: routeName,
					query: {
						productId: this.productInfo.id,
						plainId: this.plainId,
						companyId: this.companyId,
						planPriceValue: this.planPriceValue,
						price: Number(this.price),
						priceId: this.planPriceId,
						pid: this.$route.query.pid,
						pName: this.productInfo.name,
						planName: this.planName,
						user: this.userDesc,

						detailTransNo: this.detailTransNo,
					},
				});
			});
		},

		buy() {
			khs.start(this.userId);
			let send = {
				comboId: this.protectPlainList[this.selectedIndex].comboId,
				backInsureFlag: '',
			};
			getInsuerInfo(this.productInfo.id, send)
				.then(res => {
					this.product = res.product;
					if (res.bookList) {
						let bookList = res.bookList.filter(item => item.docType === '03');
						if (bookList.length) {
							this.docToConsumerLink = res.productFileServer + bookList[0].docLink;
						}
					}
					this.insurerFullName = res.insurer.insurerFullName;
					this.warningPop = true;
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},
	},
};
</script>
